<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
        btnTitle="Add New"
        :showBtn="false"
        :showAddNew="false"
        title="PR Allocation List"
        @onClickAddNewButton="onClickAddNewButton"/>
      <DateQuerySetter @onClickGo="getPrAllocations" :is-disabled="! filterByDate">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <br>
          <br>
          <label class="form-check-label" for="send-mail">Filter by date?</label>
          <input class="form-check-input ms-1"
                 id="send-mail" type="checkbox"
                 v-model="filterByDate"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Select Project</label>
          <v-select
            placeholder="Select Project"
            :options="projects"
            label="name"
            :reduce="name => name.id"
            v-model="projectId"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Department</label>
          <v-select
            placeholder="Select Department"
            v-model="departmentId"
            :options="departments"
            label="name"
            :reduce="name => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Requisition By</label>
          <v-select
            placeholder="Select Requisition By"
            v-model="requisitionUserId"
            :options="requisitionUsers"
            label="name"
            :reduce="name => name.id"
          />
        </div>
        <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
          <label class="col-form-label" for="requisition">Requisition No</label>
          <input id="requisition" type="text" class="form-control" v-model="requisitionNumber">
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Status</label>
          <v-select
            placeholder="Select Status"
            v-model="status"
            :options="approvalStatus"
            label="name"
            :reduce="name => name.id"
          />
        </div>
      </DateQuerySetter>
    </div>

    <ListTable :requisition-list="requisitionList"/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import {computed, ref, onMounted, inject} from 'vue';
import TitleButton from '@/components/atom/TitleButton'
import ListTable from '@/components/molecule/procurement/requisition/pr-allocation/ListingTable'
import Pagination from '@/components/atom/Pagination'
import RequisitionSearchInfo from "@/components/molecule/procurement/requisition/RequisitionSearchInfo";
import {useRoute, useRouter} from "vue-router";
import handleRequisitions from "@/services/modules/procurement/requisition";
import DateQuerySetter from "@/components/atom/DateQuerySetter.vue";
import {useStore} from "vuex";
import handleProjects from "@/services/modules/procurement/project";
import handleDepartments from "@/services/modules/procurement/organization-settings/department";
import handleInventory from "@/services/modules/inventory";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";

const showError = inject("showError")
const showSuccess = inject("showSuccess")
const {fetchRequisitionList, getRequisitionUserList} = handleRequisitions();
const {fetchProjects} = handleProjects();
const {fetchDepartmentList} = handleDepartments();
const {fetchHome} = handleInventory();
const {fetchPageIdFromRoute} = handleWorkflow()
const router = useRouter()
const route = useRoute();
const store = useStore()
const offset = ref(10);
let page = ref(1);
const pageId = ref(null);
let loading = ref(false);
let filterByDate = ref(false);
let requisitionList = ref({});
let projectId = ref(null);
const projects = ref([]);
const departmentId = ref(null);
const departments = ref([]);
const requisitionUserId = ref(null);
const status = ref('pending');
const requisitionNumber = ref('');
const requisitionUsers = ref([]);
const homeContent = ref([]);
let companyId = computed(() => route.params.companyId);

function onClickAddNewButton() {
  router.push({name: `pr-allocation-add`, params: route.params, query: route.query});
}

const approvalStatus = computed(() => {
  let approvalStatus = [];
  if(homeContent.value.hasOwnProperty('allocation_status')) {
    Object.keys(homeContent.value.allocation_status).forEach( id => {
      approvalStatus.push({
        id: id,
        name: homeContent.value.allocation_status[id],
      })
    })
  }
  return approvalStatus;
})

async function getPrAllocations() {
  try {
    requisitionList.value = [];
    loading.value = true;
    const res = await fetchRequisitionList(getQuery());
    if (res.status) {
      requisitionList.value = res.data;
      setPaginationData({
        records: res.data.total,
        perPage: res.data.per_page,
        currentPage: res.data.current_page,
      })
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    loading.value = false;
  }
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end
  query += '&is_all=' + 1
  query += '&without_user_check=' + 1
  query += '&page_id=' + pageId.value
  if(projectId.value !== null) {
    query += '&project_id=' + projectId.value
  }
  if(departmentId.value !== null) {
    query += '&department_id=' + departmentId.value
  }
  if(requisitionUserId.value !== null) {
    query += '&user_id=' + requisitionUserId.value
  }
  if (requisitionNumber.value) {
    query += '&requisition_no=' + requisitionNumber.value
  }
  if(! filterByDate.value) {
    query += '&without_date_filter=' + 1
  }
  query += '&allocation_status=' + (status.value !== null ? status.value : 'pending')
  query += '&page=' + route.query.page ?? page.value
  return query
}

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    getProjects();
  }, 100)
}

onMounted(async () => {
  loading.value = true;
  let query = '?company_id=' + companyId.value

  Promise.all([
    getPrAllocations(),
    fetchProjects(query).then( res => {
      if(res.status) {
        projects.value = res.data;
      }
    }),
    fetchHome().then( res => {
      if(res.status) {
        homeContent.value = res.data;
      }
    }),
    fetchDepartmentList(query).then( res => {
      if(res.status) {
        departments.value = res.data;
      }
    }),
    fetchPageIdFromRoute('requisition-approval').then(res => {
      if(res.status) {
        pageId.value = res.data.id;
      }
    }),
    getRequisitionUserList(query).then( res => {
      if(res.status) {
        requisitionUsers.value = res.data;
      }
    }),
  ])
    .then ( () => loading.value = false)
    .catch( (err) => {
      loading.value = false
      showError( err.response.message)
    })
    .finally( () => loading.value = false)
})

</script>
